export enum PageType {
  COURSE = 'course',
  COURSES = 'courses',
  HOME = 'home',
}

export enum LandingPromoCodeSlug {
  // Inactive promos
  DATA_ANALYTICS_PRESALE_START_UA = 'data-analytics-presale-start-ua',
  DATA_ANALYTICS_PRESALE_END_UA = 'data-analytics-presale-end-ua',
  QA_BR_PRESALE_START = 'qa-br-presale-start',
  QA_BR_PRESALE_END = 'qa-br-presale-end',
  PYTHON_PL_PRESALE_START = 'python-pl-presale-start',
  PYTHON_PL_PRESALE_END = 'python-pl-presale-end',
  KNOWLEDGE_DAY_PROMO = 'knowledge-day-promo',
  FLYER_ONE_PROMO = 'flyer-one-promo',
  MATES_ANNIVERSARY_PROMO = '10-mate-anniversary',
  MATES_ANNIVERSARY_PROMO_PL = '10-mate-anniversary-pl',

  // Active promos
  CUSTOMER_WEEK_PROMO = 'customer-week-promo',
  FREE_ENGLISH_PROMO = 'free-english-promo',
  ONE_PLUS_ONE_PROMO = 'one-plus-one-promo',
  ONE_PLUS_ONE_PROMO_BR = 'one-plus-one-promo-br',
  MONETARY_PROMO_UA = 'monetary-promo-ua',
  UNLIM_PROMO = 'unlim-promo',
  LIMITED_OFFER_PL = 'limited-offer-pl',
  LIMITED_OFFER_BR = 'limited-offer-br',
  SECRET_BONUS_PROMO = 'secret-bonus-promo',
  DESIGN_WEEK_PROMO = 'design-week-promo',
  DEVOPS_WEEK_PROMO = 'devops-week-promo',
  INDEPENDENCE_DAY_PROMO_PL = 'independence-day-promo-pl',
}

export enum PromoSectionType {
  BANNER = 'banner',
  CARD = 'card',
}
