export enum ProfessionSlugs {
  Qa = 'qa',
  DataAnalytics = 'data-analytics',
  Recruitment = 'recruitment',
  DigitalMarketing = 'digital-marketing',
  UiUx = 'ui-ux',
  Python = 'python',
  Java = 'java',
  Frontend = 'frontend',
  Fullstack = 'fullstack',
  DevOps = 'devops',
}
